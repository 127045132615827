import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import {DocsExport} from "@/game-destiny2/components/LoadoutBuilderOverview.jsx";
export const {ItemTarget, Placeholder, SubclassSection, WeaponsSection, ArmorSection, StatsSection} = DocsExport;
function _createMdxContent(props) {
  const _components = {
    a: "a",
    code: "code",
    h2: "h2",
    h3: "h3",
    ..._provideComponents(),
    ...props.components
  };
  if (!Placeholder) _missingMdxReference("Placeholder", false);
  if (!Placeholder.Aspect) _missingMdxReference("Placeholder.Aspect", true);
  if (!Placeholder.Helmet) _missingMdxReference("Placeholder.Helmet", true);
  if (!Placeholder.Weapon) _missingMdxReference("Placeholder.Weapon", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h2, {
      id: "build-calculator",
      children: _jsx(_components.a, {
        href: "#build-calculator",
        children: "Build Calculator"
      })
    }), "\n", _jsx(_components.h3, {
      id: "stats-section",
      children: _jsx(_components.a, {
        href: "#stats-section",
        children: "Stats Section"
      })
    }), "\n", _jsxs(_components.code, {
      preview: "flex column gap-3",
      hideSource: true,
      className: "language-jsx",
      code: `  <StatsSection />`,
      children: ["  ", _jsx(StatsSection, {})]
    }), "\n", _jsx(_components.h3, {
      id: "item-placeholders",
      children: _jsx(_components.a, {
        href: "#item-placeholders",
        children: "Item Placeholders"
      })
    }), "\n", _jsxs(_components.code, {
      preview: "flex column gap-3",
      hideSource: true,
      className: "language-jsx",
      code: `  <ItemTarget text="Kinetic Weapon" icon={<Placeholder.Weapon />} />
  <ItemTarget tooltip="With Tooltip" icon={<Placeholder.Aspect />} />
  <ItemTarget text="Right Aligned" icon={<Placeholder.Helmet />} className="right-aligned" />
  <ItemTarget text="Long Name With Text Plz Ellipsize" icon={<Placeholder.Weapon />} />`,
      children: ["  ", _jsx(ItemTarget, {
        text: "Kinetic Weapon",
        icon: _jsx(Placeholder.Weapon, {})
      }), _jsx(ItemTarget, {
        tooltip: "With Tooltip",
        icon: _jsx(Placeholder.Aspect, {})
      }), _jsx(ItemTarget, {
        text: "Right Aligned",
        icon: _jsx(Placeholder.Helmet, {}),
        className: "right-aligned"
      }), _jsx(ItemTarget, {
        text: "Long Name With Text Plz Ellipsize",
        icon: _jsx(Placeholder.Weapon, {})
      })]
    }), "\n", _jsx(_components.h3, {
      id: "subclass-section",
      children: _jsx(_components.a, {
        href: "#subclass-section",
        children: "Subclass Section"
      })
    }), "\n", _jsxs(_components.code, {
      preview: "flex column gap-3",
      hideSource: true,
      className: "language-jsx",
      code: `  <SubclassSection />`,
      children: ["  ", _jsx(SubclassSection, {})]
    }), "\n", _jsx(_components.h3, {
      id: "weapons-section",
      children: _jsx(_components.a, {
        href: "#weapons-section",
        children: "Weapons Section"
      })
    }), "\n", _jsxs(_components.code, {
      preview: "flex column gap-3",
      hideSource: true,
      className: "language-jsx",
      code: `  <WeaponsSection />`,
      children: ["  ", _jsx(WeaponsSection, {})]
    }), "\n", _jsx(_components.h3, {
      id: "armor-section",
      children: _jsx(_components.a, {
        href: "#armor-section",
        children: "Armor Section"
      })
    }), "\n", _jsxs(_components.code, {
      preview: "flex column gap-3",
      hideSource: true,
      className: "language-jsx",
      code: `  <ArmorSection />`,
      children: ["  ", _jsx(ArmorSection, {})]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
export const meta = () => JSON.parse('{"title":[null,"Blitz Docs"],"description":"Docs for Blitz App"}');
